@import"../../../../assets/colors/colors.scss";



.bgContainer {
    background-color: #ffffff;
    padding: 12px 22px !important;
    border-radius: 6px;
    margin: 8px;
    // border: 1px solid $gray_d5dee8;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
}

.icon {
    width: 50px;
    margin-bottom: 12px;
}