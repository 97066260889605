@import"../../../../assets/colors/colors.scss";

.root {
    margin-top: 40px;
    background-color: $white;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;

    @media(max-width:991px) {
        height: 100%;
        padding-top: 60px;
    }
}

.titleColor {
    color: $primary;
}

.googleImg {
    width: 160px;
}

.googleContainer {
    margin-bottom: 26px;
}

.heroImage {
    width: 100%;

    @media(max-width:991px) {
        width: 60%;
    }

    @media(max-width:600px) {
        width: 80%;
    }
}

.orderOne {
    @media(max-width:991px) {
        order: 2 !important;
    }
}

.orderTwo {
    @media(max-width:991px) {
        order: 1;
    }
}