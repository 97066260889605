@import"../../assets/colors/colors.scss";

.root {
    width: 85%;
    margin: auto;
    padding: 30px 0px 40px;

    @media(max-width:600px) {
        width: 90%;
    }
}