* {
  box-sizing: border-box;
}

body {
  margin: 0px;
  padding: 0px;
  font-family: "Roboto", sans-serif !important;
  background-color: #f6fbfd !important;
}

p {
  margin: 0;
  padding: 0;

}

h1,
h2,
h3,
h4,
h5 {
  margin: 0;
  padding: 0;
  font-family: "Poppins", sans-serif !important;
}