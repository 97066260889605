@import"../../../../assets/colors/colors.scss";

.root {
    background-color: $white;
}

.ul {
    padding: 0px;
    list-style: none;
}

.boxContainer {
    margin: 10px;
    background-color: #f4f4f4;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.flexContainer {
    display: flex;
}

.checkIcon {
    width: 16px;
    margin-top: 5px;
    margin-right: 10px;
}

.featuresImages {
    width: 80%;
}

.gridContainer {
    margin-bottom: 50px;
}

.orderOne {
    @media(max-width:767px) {
        order: 1 !important;
    }
}

.orderOne:nth-child(even) {
    @media(max-width:767px) {
        order: 2 !important;
    }
}

.orderTwo {
    @media(max-width:767px) {
        order: 2 !important;
    }
}

.orderTwo:nth-child(even) {
    @media(max-width:767px) {
        order: 1 !important;
    }
}