@import"../../assets/colors/colors.scss";

.default {
    margin: 0;
}

.normal {
    margin: 0;
    font-size: 18px;
    font-weight: 400;

    @media (max-width: 699px) {
        font-size: 14px;
    }
}

.description_14 {
    font-size: 14px;
    font-weight: 400;
    color: $gray;
    letter-spacing: 1px;

    @media (max-width: 599px) {
        font-size: 12px;
    }
}

.description_16 {
    font-size: 16px;
    font-weight: 400;
    color: $gray ;
    letter-spacing: 1px;

    @media (max-width: 699px) {
        font-size: 14px;
    }

    @media (max-width: 599px) {
        font-size: 12px;
    }
}

.description_20 {
    font-size: 20px;
    font-weight: 400;
    color: $gray ;
    letter-spacing: 1px;

    @media (max-width: 699px) {
        font-size: 14px;
    }

    @media (max-width: 599px) {
        font-size: 12px;
    }
}

.heading_42 {
    font-size: 42px;
    font-weight: 600;

    @media(max-width: 992px) {
        font-size: 40px;
    }

    @media(max-width: 699px) {
        font-size: 28px;
    }
}

.subHeading_32 {
    font-size: 32px;
    font-weight: 600;

    @media (max-width: 699px) {
        font-size: 22px;
    }
}

.subHeading_28 {
    font-size: 28px;
    font-weight: 400;

    @media (max-width: 699px) {
        font-size: 18px;
    }
}

.subHeading_24 {
    font-size: 24px;
    font-weight: 400;

    @media (max-width: 699px) {
        font-size: 16px;
    }
}

.title_80 {
    font-size: 80px;
    font-weight: 400;
    line-height: 1.2 !important;

    @media only screen and (max-width: 1191px) {
        font-size: 40px !important;
    }

    @media only screen and (max-width: 699px) {
        font-size: 32px !important;
    }
}